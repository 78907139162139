import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, useParams, Redirect, Switch } from "react-router-dom";
import ReactDOM from 'react-dom';
import './index.css';

const RouterPage = () => {
  //Keep dyno awake
  //https://devcenter.heroku.com/articles/free-dyno-hours#dyno-sleeping
  // useEffect(() => {
  //   const interval = setInterval(function () {
  //     fetch('https://dessyysergio.herokuapp.com/')
  //       .then(response => console.log(response));
  //   }, 25 * 60 * 1000); // every 25 minutes
  //   return () => clearInterval(interval);
  // }, []);

  const today = getCurrentMonth();

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to={`/${today.getFullYear()}/${today.getMonth() + 1}`} />
        </Route>
        <Route path="/peek/:year/:month" render={(props) => <Page {...props} peek={true} />} />
        <Route path="/:year/:month" component={Page} />
      </Switch>
    </Router>
  );
}

const Page = (props) => {
  let { year, month } = useParams();
  const [quotes, setData] = useState([]);
  useEffect(() => { getData(setData) }, [])

  if (quotes.length === 0) return <></>;

  const data = getMonthData(year, month, quotes, props.peek);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: `url(/img/background/${data.background_img}.jpg)` }}>
      <div className="wrapper" >
        <Main currentRow={data.currentRow} date={data.currentDate} cute_img={data.cute_img} />
        <Link className="prev" to={`${data.prev}`} style={{ display: data.prevDisplay }} />
        <Link className="next" to={`${data.next}`} style={{ display: data.nextDisplay }} />
      </div>
    </div>
  );
}

const Main = (props) => {
  const days = Math.floor((props.date - new Date(2007, 5, 13)) / (1000 * 60 * 60 * 24));

  return (
    <>
      <div className="quote">
        {props.currentRow.quote.split('\n').map((l, x) => { return (<span key={x}>{l} </span>); })}
        <div className="author">
          {props.currentRow.author}
        </div>
      </div>
      <div className="image">
        <img src={`/img/cute/${props.cute_img}`} alt="cute" onError={(event) => event.target.src = '/img/cute/confused.gif'} />
      </div>
      <div className="bottom">
        <Bottom days={days} anniversary={props.currentRow.anniversary} />
      </div>
    </>
  );
}

const Bottom = (props) => {
  if (props.anniversary) {
    return (props.anniversary.split('\n').map((l, x) => { return (<span key={x}>{l} </span>); }))
  }
  return (<>{props.days} días y contando!<br />HEIYMA</>)
}


const getData = (setData) => {
  fetch('/quotes.json', {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (myJson) {
      setData(myJson)
    });
}

const getCurrentMonth = () => {
  let today = new Date();
  if (today.getDate() < 13) {
    today = moveMonth(today, -1);
  }
  return new Date(today.getFullYear(), today.getMonth(), today.getDate());
};

const getMonthData = (year, month, data, peek = false) => {
  let displayDate = new Date(year, month - 1, 1);
  let prev = moveMonth(displayDate, -1);
  let next = moveMonth(displayDate, 1);
  let background_img = "confused";
  let cute_img = "confused.gif";
  let currentRow = [];

  const ix = getMonthCount(displayDate) - 72;

  if (0 <= ix && ix < data.length && (peek || displayDate <= getCurrentMonth())) {
    currentRow = data[ix];
    background_img = ix % 84;
    cute_img = `${year}/${month - 1}.png`;
  }
  else {
    currentRow = { quote: "Aún no hay frase para este mes...\nMientras tanto... un oso confundido\ny el contador de dias!", author: "Yo" };
    displayDate = new Date();

    if (ix < 0) {
      next = new Date(2013, 5, 13);
    }
    else if (ix >= data.length) {
      prev = getCurrentMonth();
    }
  }

  return {
    currentRow,
    currentDate: displayDate,
    background_img,
    cute_img,
    prevDisplay: ix > 0 ? 'inherit' : 'none',
    nextDisplay: ix < data.length - 1 && next <= getCurrentMonth() ? 'inherit' : 'none',
    prev: `/${prev.getFullYear()}/${prev.getMonth() + 1}`,
    next: `/${next.getFullYear()}/${next.getMonth() + 1}`,
  };
};

const moveMonth = (date, i) => {
  var d = new Date(date);
  d.setDate(1);
  d.setMonth(d.getMonth() + i);
  return d;
}

const getMonthCount = (toMonth) => {
  let fromMonth = new Date(2007, 5, 13);//June 13, 2007
  let months = (toMonth.getFullYear() - fromMonth.getFullYear()) * 12;
  months -= fromMonth.getMonth();
  months += toMonth.getMonth();
  return months;
}

// ========================================

ReactDOM.render(<RouterPage />, document.getElementById("root"));
